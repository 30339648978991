import React from "react"
import "./App.scss"
import { Switch, Route } from "react-router-dom"

import Demo from "./page-demo/Demo"
import MainDemo from "./home/MainDemo"
import Startup from "./home/Startup"
import Paralax from "./home/Paralax"
import HomePortfolio from "./home/HomePortfolio"
import DigitalAgency from "./home/DigitalAgency"
import CreativeAgency from "./home/CreativeAgency"
import PersonalPortfolio from "./home/PersonalPortfolio"
import Business from "./home/Business"
import StudioAgency from "./home/StudioAgency"
import PortfolioLanding from "./home/PortfolioLanding"
import CreativeLanding from "./home/CreativeLanding"
import HomeParticles from "./home/HomeParticles"
import CreativePortfolio from "./home/CreativePortfolio"
import DesignerPortfolio from "./home/DesignerPortfolio"
import InteriorLanding from "./home/Interior"
import CorporateBusiness from "./home/CorporateBusiness"

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo"
import DarkPortfolioLanding from "./dark/PortfolioLanding"

// Element Layout
import Service from "./elements/Service"
import ServiceDetails from "./elements/ServiceDetails"
import About from "./elements/About"
import Contact from "./elements/Contact"
import PortfolioDetails from "./elements/PortfolioDetails"
import Blog from "./elements/Blog"
import BlogDetails from "./elements/BlogDetails"
import error404 from "./elements/error404"

// Blocks Layout

import Team from "./blocks/Team"
import Counters from "./blocks/Counters"
import Testimonial from "./blocks/Testimonial"
import Portfolio from "./blocks/Portfolio"
import VideoPopup from "./blocks/VideoPopup"
import Gallery from "./blocks/Gallery"
import Brand from "./blocks/Brand"
import ProgressBar from "./blocks/ProgressBar"
import ContactForm from "./blocks/ContactForm"
import GoogleMap from "./blocks/GoogleMap"
import Columns from "./blocks/Columns"
import PricingTable from "./blocks/PricingTable"

function App() {
	return (
		<div className="App">
			<Switch>
				<Route exact path={`/`} component={Demo} />
				<Route path={`/main-demo`} component={MainDemo} />
				<Route path={`/dark-main-demo`} component={DarkMainDemo} />
				<Route path={`/startup`} component={Startup} />
				<Route path={`/paralax`} component={Paralax} />

				<Route path={`/digital-agency`} component={DigitalAgency} />
				<Route path={`/creative-agency`} component={CreativeAgency} />
				<Route path={`/personal-portfolio`} component={PersonalPortfolio} />
				<Route path={`/studio-agency`} component={StudioAgency} />
				<Route path={`/business`} component={Business} />
				<Route path={`/portfolio-home`} component={HomePortfolio} />
				<Route path={`/portfolio-landing`} component={PortfolioLanding} />
				<Route path={`/creative-landing`} component={CreativeLanding} />
				<Route path={`/home-particles`} component={HomeParticles} />
				<Route path={`/dark-portfolio-landing`} component={DarkPortfolioLanding} />
				<Route path={`/designer-portfolio`} component={DesignerPortfolio} />
				<Route path={`/creative-portfolio`} component={CreativePortfolio} />
				<Route path={`/interior-landing`} component={InteriorLanding} />
				<Route path={`/corporate-business`} component={CorporateBusiness} />
				<Route path={`/service`} component={Service} />
				<Route path={`/service-details`} component={ServiceDetails} />
				<Route path={`/contact`} component={Contact} />
				<Route path={`/about`} component={About} />
				<Route path={`/portfolio-details`} component={PortfolioDetails} />
				<Route path={`/blog`} component={Blog} />
				<Route path={`/blog-details`} component={BlogDetails} />
				<Route path={`/team`} component={Team} />
				<Route path={`/counters`} component={Counters} />
				<Route path={`/testimonial`} component={Testimonial} />
				<Route path={`/portfolio`} component={Portfolio} />
				<Route path={`/video-popup`} component={VideoPopup} />
				<Route path={`/gallery`} component={Gallery} />
				<Route path={`/clint-logo`} component={Brand} />
				<Route path={`/progressbar`} component={ProgressBar} />
				<Route path={`/contact-form`} component={ContactForm} />
				<Route path={`/google-map`} component={GoogleMap} />
				<Route path={`/columns`} component={Columns} />
				<Route path={`/pricing-table`} component={PricingTable} />

				<Route path={`/404`} component={error404} />
				<Route component={error404} />
			</Switch>
		</div>
	)
}

export default App
